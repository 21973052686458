* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

html body {
  background: #02285f;
  background-color: #330033;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.adarshm__header {
  background: transparent;
  font: 400 13px "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}

.adarshm__container {
  min-height: 70px;
}

.adarshm__link {
  color: #ffffff !important;
  text-decoration: none;
  font: 400 13px "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}

a.adarshm__link:hover {
  color: #64ffda !important;
  transition: 0.2s ease;
}

.adarshm__link.adarshm__resume {
  border: 2px solid #64ffda;
  border-radius: 4px;
  padding: 10px 18px !important;
  margin-left: 20px;
}

.adarshm__nav {
  padding: 10px !important;
  margin-left: auto;
  min-width: 560px;
  justify-content: space-between;
  align-items: center;
}

.adarshm__container__section {
  display: flex;
  align-items: center;
}

h2.adarshm__heading__small {
  margin: 0px 0px 30px 4px;
  color: #64fcda;
  font-family: "Mono";
}

h2.adarshm__heading__big {
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
  color: #ccd6f6;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

h3.adarshm__heading__big {
  margin: 0px;
  font-size: clamp(20px, 4vw, 40px);
  color: #8892b0;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

p.adarshm__description {
  margin: 20px 0px 0px;
  max-width: 500px;
  color: #8892b0;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

.adarshm__getintouch {
  border: 2px solid #64ffda;
  border-radius: 4px;
  padding: 10px 18px !important;
  margin: 40px 0;
  max-width: fit-content;
}

.adarshm__media__links {
  position: fixed;
  left: 40px;
  bottom: 0;
  padding: 10px;
}

.adarshm__media__links:after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background: aliceblue;
  position: relative;
  left: 3px;
}

.adarshm__media__links .adarshm__svg__icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  list-style: none;
  width: 10%;
  margin-left: -24px;
}

.adarshm__media__links .adarshm__svg__icons li svg {
  height: 20px;
  color: #cccccc;
}

.adarshm__content__area {
  min-width: 100%;
}

.adarshm__mailid {
  position: fixed;
  right: 68px;
  bottom: 0;
  width: 40px;
  padding: 10px;
  letter-spacing: 0.1em;
  writing-mode: vertical-rl;
  display: flex;
}

.adarshm__mailid:after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background: #f8fffd;
  position: relative;
  left: -3px;
}

a.adarshm__mailid__link {
  color: #cccccc;
  text-decoration: none;
}

a.adarshm__mailid__link:hover {
  color: #53e5b9;
}

.adarshm__nav__desktop {
  display: block;
}
.adarshm__nav__mobile {
  display: none;
}

a.adarshm__link.getintouch__btn::before {
  position: relative;
  content: "<";
  left: -6px;
}

a.adarshm__link.getintouch__btn::after {
  position: relative;
  content: "/>";
  right: -6px;
}

.adarshm__img {
  width: 240px;
  border-radius: 50%;
  border: 2px solid #a8a8a8;
  padding: 9px;
  background: #a2a2a2;
  transition: 0.2s ease;
}
.adarshm__img:hover {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  transition: 0.2s ease;
  border: 0;
  transform: scale(1.1);
}

.adarshm__content__section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  margin: auto;
}

.adarshm__aboutcontainer {
  display: flex;
  margin: auto 6%;
}

.adarshm__about {
  max-width: 100%;
}

h2.adarshm__about__heading {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin: auto 11% 2%;
  font-size: clamp(24px, 5vw, 29px);
  white-space: nowrap;
  color: #ccd6f6;
}

h2.adarshm__about__heading::after {
  content: "";
  display: block;
  position: relative;
  top: 2px;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background: #ccd5f1;
}

.adarshm__about__img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 78%;
  margin: auto;
}

.adarshm__aboutcontainer.container {
  /* min-height: 60vh; */
  margin: auto;
}

.WorkDetails.container {
  /* min-height: 40vh; */
  margin: auto;
}

p.adarshm__description__full {
  color: #8892b0;
  line-height: 1.9rem;
  width: 100%;
}

ul.skills-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(140px, 200px));
  padding: 0px;
  margin: 20px 0px 0px;
  overflow: hidden;
  color: #64ffda;
  list-style: none;
}

ul.skills-list li::before {
  content: "▹";
  position: relative;
  left: 0px;
  color: var(--green);
  font-size: var(--fz-sm);
  line-height: 12px;
  margin-right: 9px;
}

img.d-block.a__img {
  width: 260px;
  height: 269px;
  position: relative;
  z-index: 9;
  padding: 9px;
}

.carousel-control-next,
.carousel-control-prev {
  visibility: hidden;
}

.carousel {
  width: 290px;
  height: 278px;
}

.carousel-inner {
  border: 2px solid #ffffff;
  position: relative;
  width: 90%;
  overflow: hidden;
  height: 270px;
}

.wordDetails__container {
  display: grid;
  grid-template-columns: 18% 82%;
  max-width: 78%;
  margin: 2rem auto;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #58efbe !important;
  border-left: 2px solid #58efbe;
  border-radius: 0;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  position: relative;
  transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 13px;
  border-radius: 0;
  white-space: nowrap;
}

.nav-item > .nav-link {
  color: #d9d9d9 !important;
  height: 40px;
  font-size: 13px;
  background: 0 0;
}

.layer__one {
  display: flex;
  color: #ffffff;
}

h2.workCard__position,
h2.workCard__at,
h2.workCard__location {
  font-size: 22px;
  font-weight: 500;
}

p.achivements {
  font-size: 15px;
  font-weight: 400;
  color: #9e9eb1;
  line-height: 1.6rem;
  position: relative;
  padding-left: 9px;
  margin-bottom: 10px;
}

p.achivements:before {
  content: "▹";
  position: relative;
  left: -9px;
  color: #53e7ba;
}

p.wordCard__startdate,
p.wordCard__to,
p.wordCard__currentStatus {
  font-size: 13px;
}

h2.workCard__at,
h2.workCard__location {
  color: #53e7ba;
}

.github__latest__container {
  max-width: 100%;
  min-height: 40vh;
  margin: auto;
}

ul.github__grid {
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 78%;
  margin: auto;
}

.folder,
.github__links {
  width: 29px;
  color: #ccd6f6;
}

.layer__top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 18px;
}

.project-description p {
  color: #8892b0;
  font-size: 14px;
}

ul.tech__list {
  display: flex;
  color: #ffffff;
  justify-content: flex-end;
}

ul.tech__list li {
  padding: 0 10px;
  font-size: 12px;
  color: #7c92ba;
}

li.github__list {
  background: rgb(59 3 59 / 25%);
  padding: 16px;
  margin: 20px;
  box-shadow: 0 8px 32px 0 rgb(59 3 59 / 25%);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

h3.github__title a {
  text-decoration: none;
  font-size: 20px;
  color: #4fdeb5;
}

.contact__form__inner {
  display: grid;
  grid-template-columns: 60% 40%;
}

form {
  display: grid;
  max-width: 78%;
  padding: 16px;
  grid-gap: 16px;
  background: rgba(51, 0, 51, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

span.err__message {
  color: #b5b9bf;
  font-size: 12px;
  font-weight: 400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

textarea#message {
  height: 120px !important;
}

input.submit__btn {
  background: rgb(102 76 131 / 24%);
  border: none;
  color: #ffffff;
  height: 40px;
  border-radius: 2px;
  margin-left: auto;
  min-width: 40%;
}

input.submit__btn:hover {
  background: #5e28535e;
}

.contact__details {
  max-width: 78%;
  margin-left: auto;
  margin-right: 4%;
}

p.contact__data {
  color: #8892b0;
  letter-spacing: 0.2px;
}

ul.svg__icons {
  display: flex;
  padding: 0;
  margin-top: 18px;
}

ul.svg__icons li {
  margin-right: 12px;
  border: 2px solid #8892b0;
  border-radius: 50%;
  padding: 4px;
}

ul.svg__icons li a svg {
  padding: 4px;
  height: 24px;
  color: #8892b0;
}

.adarshm__media__links .adarshm__svg__icons li svg:hover,
ul.svg__icons li a svg:hover {
  color: #53e5b9;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(2.5rem + 2px) !important;
}

.form-floating > label {
  font-size: 14px;
  color: #aea9a3;
  padding: 0.6rem 0.75rem !important;
}

.footer {
  background: #482248;
  padding: 20px 0;
}

.footer__inner.container {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2rem;
}

p.link {
  margin: 0;
}

::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #482248;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e28535e;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .adarshm__content__section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 68%;
  }

  h2.adarshm__heading__big {
    font-size: clamp(32px, 8vw, 68px);
  }

  h3.adarshm__heading__big {
    font-size: clamp(14px, 4vw, 29px);
    margin-top: 6px;
  }

  .adarshm__img {
    width: 196px;
  }
}

@media only screen and (max-width: 767px) {
  .adarshm__nav__mobile {
    display: block;
    background: #0a192f;
  }

  .adarshm__nav__desktop,
  .adarshm__mailid,
  .adarshm__media__links {
    display: none;
  }

  .adarshm__img {
    margin: 0 0 40px;
  }

  .nav {
    flex-wrap: nowrap;
  }

  .adarshm__content__section {
    max-width: 94%;
    flex-direction: column-reverse;
  }

  .adarshm__about__img {
    display: block;
    max-width: 100%;
    margin: 10px;
  }

  h2.adarshm__about__heading {
    margin: 12px;
  }

  .container.adarshm__container__section {
    margin: 2rem auto 0 auto;
    padding: 20px;
  }

  .adarshm__aboutcontainer.container {
    margin: 0rem auto 4rem auto;
  }

  ul.skills-list {
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 10px;
  }

  .wordDetails__container {
    grid-template-columns: auto;
    max-width: 100%;
    margin: 2rem 1rem;
  }

  h2.workCard__position,
  h2.workCard__at,
  h2.workCard__location {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 4px;
  }

  ul.github__grid {
    grid-template-columns: auto;
    max-width: 100%;
    padding: 0;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    border-bottom: 2px solid #58efbe;
    border-left: 0;
  }

  .contact__form__inner {
    grid-template-columns: auto;
    grid-gap: 16px;
  }

  form {
    max-width: 100%;
  }

  .contact__details {
    max-width: 94%;
    margin: auto;
  }
}
